import { Box, Spinner } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { Organization, useGetOrganizationBillingAccountQuery } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { ViewBillingAccount } from '../../components/billing/ViewBillingAccount';

export const Billing = () => {
  const { organization = {} as Organization } = useOrganization();
  const { isLoading, data } = useGetOrganizationBillingAccountQuery(organization.id);

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <PageHeader title="Billing" description="Manage your billing information and usage" />

      {isLoading && <Spinner />}

      {data && <ViewBillingAccount billingAccount={data} />}
    </Box>
  );
};

export default Billing;
