import { Box, Button, Popover, toast, useOpenClose, useBreakpoint } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { TagList } from './TagList';
import { useCallback } from 'react';
import { useDeleteCodeMutation } from '../../services/zelda/codes';
import { Code } from '../../types/zelda';
import { ConfirmModal } from '../common/ConfirmModal';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { ListItem } from '../ListItem';
import { DateCreated } from '../DateCreated';

type ShortLinkProps = {
  code: Code;
  orgTags?: string[];
};

export const ShortLink = ({ code, orgTags }: ShortLinkProps) => {
  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const [deleteShortLink, { isLoading: isDeleting, error }] = useDeleteCodeMutation();

  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();
  const { isOpen: isCopyModalOpen, handleClose: closeCopyModal, handleOpen: openCopyModal } = useOpenClose();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const { isPhone } = useBreakpoint();

  const ability = useOrganizationAbilityContext();
  const canManageThisLink = ability.can('manage', code);

  const shortLink = `${code.domain}/${code.code}`;

  const customMessages = {
    default: 'Failed to remove Short URL',
  };

  const handleCopyUrl = () => {
    toast.success('Copied to clipboard');
    navigator.clipboard.writeText(`https://${shortLink}`);
  };

  const handleDelete = useCallback(async () => {
    const { error } = await deleteShortLink({ codeId: code.id, organizationId });
    if (!error) {
      toast.success('Short URL deleted!');
      closeDeleteModal();
    }
  }, [code.id, closeDeleteModal, deleteShortLink, organizationId]);

  const popoverContent = (
    <>
      <Link to={code.id}>
        <Button variant="secondary" size="sm">
          View details
        </Button>
      </Link>
      {canManageThisLink && (
        <Button
          variant="danger"
          size="sm"
          onClick={() => {
            closePopover();
            openDeleteModal();
          }}
        >
          Delete
        </Button>
      )}
    </>
  );

  const infoText = isPhone ? (
    <Box width="100" fontSize="sm" direction="row" gap="xs" alignItems="center">
      <Link to={`https://${shortLink}`} target="_blank" rel="noopener noreferrer" className="truncate">
        {shortLink}
      </Link>
      <Button
        variant="tertiary"
        aria-label="copy url"
        iconPrefix="copy-document"
        size="sm"
        onClick={handleCopyUrl}
      ></Button>
    </Box>
  ) : (
    <Box fontSize="sm" direction="row" gap="xs" alignItems="center">
      <Link to={`https://${shortLink}`} target="_blank" rel="noopener noreferrer">
        {shortLink}
      </Link>
      <Popover
        content="copy short URL"
        isOpen={isCopyModalOpen}
        placement="top"
        offsetFromTarget={4}
        contentContainerProps={{
          padding: 'md',
          color: 'inverse',
          background: 'inverse',
          fontSize: 'xs',
        }}
      >
        <Button
          onMouseOver={openCopyModal}
          onMouseOut={closeCopyModal}
          onFocus={openCopyModal}
          onBlur={closeCopyModal}
          variant="tertiary"
          aria-label="copy url"
          iconPrefix="copy-document"
          size="sm"
          onClick={handleCopyUrl}
        />
      </Popover>
    </Box>
  );

  return (
    <>
      <ListItem
        title={{ label: code.title, link: code.id }}
        subtitle={
          <>
            <Link
              to={code.long_url}
              target="_blank"
              rel="noopener noreferrer"
              className="font-color-secondary truncate"
            >
              {code.long_url}
            </Link>
            {code.id && <TagList codeId={code.id} tags={code.tags ?? []} orgTags={orgTags ?? []} />}
          </>
        }
        infoText={infoText}
        popover={{ content: popoverContent, isOpen: isPopoverOpen, onClickOutside: closePopover }}
        togglePopover={togglePopover}
      >
        <Box fontSize="xs" color="secondary" flex="auto" alignItems="flex-end">
          <DateCreated dateCreated={code.createdAt} />
        </Box>
      </ListItem>
      <ConfirmModal
        message={`Are you sure you want to delete ${code.title}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${code.title}`}
        error={error}
        customErrorMessages={customMessages}
      />
    </>
  );
};
