import React from 'react';
import { Box, Button, Popover, PopoverProps } from '@hyphen/hyphen-components';
import { Link, To } from 'react-router-dom';
import BadgePopover, { BadgePopoverProps } from './BadgePopover';

interface ListItemProps {
  title: {
    label: string;
    link?: To;
  };
  badge?: BadgePopoverProps;
  subtitle?: React.ReactNode;
  infoText?: React.ReactNode;
  children?: React.ReactNode;
  popover?: Pick<PopoverProps, 'content' | 'isOpen' | 'onClickOutside'>;
  togglePopover?: () => void;
  reservePopoverBtnSpace?: boolean;
}

export const ListItem = ({
  badge,
  children,
  title,
  subtitle,
  infoText,
  popover,
  togglePopover,
  reservePopoverBtnSpace = false,
}: ListItemProps) => {
  return (
    <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
      <Box alignItems="center" direction="row" gap={{ base: 'lg', tablet: '2xl' }} padding="3xl">
        <Box
          flex="auto"
          direction={{ base: 'column', desktop: 'row' }}
          gap={{ base: 'lg', desktop: '2xl' }}
          alignItems={{ base: 'flex-start', desktop: 'center' }}
        >
          {badge && (
            <Box width="72px">
              <BadgePopover {...badge} />
            </Box>
          )}
          <Box
            display="block"
            childGap="xs"
            minWidth={{ base: '100', desktop: '40' }}
            width={infoText || children ? { base: '100', desktop: '40' } : undefined}
          >
            <Box
              fontSize={{ base: 'sm', tablet: 'md' }}
              fontWeight="bold"
              alignItems="center"
              direction="row"
              gap="lg"
            >
              {title?.link ? <Link to={title.link}>{title.label}</Link> : title.label}
            </Box>
            {subtitle && (
              <Box alignItems="flex-start" fontSize={{ base: 'xs', tablet: 'sm' }} gap="xs" color="secondary">
                {subtitle}
              </Box>
            )}
          </Box>
          {infoText ? (
            <Box flex="auto" style={{ maxWidth: '100%' }} fontSize={{ base: 'xs', tablet: 'sm' }} color="secondary">
              {infoText}
            </Box>
          ) : null}
          {children}
        </Box>

        {popover?.content ? (
          <Popover
            content={popover.content}
            isOpen={popover.isOpen ?? false}
            placement="bottom-end"
            hasArrow={false}
            offsetFromTarget={3}
            contentContainerProps={{
              padding: 'sm',
              background: 'inverse',
              color: 'inverse',
              gap: 'xs',
              borderWidth: 'sm',
              borderColor: 'default',
            }}
            onClickOutside={popover.onClickOutside}
          >
            <Button
              variant="tertiary"
              size="sm"
              iconPrefix="dots"
              onClick={togglePopover}
              aria-label="open menu"
            ></Button>
          </Popover>
        ) : reservePopoverBtnSpace ? (
          <Box width="36px" height="24px" />
        ) : null}
      </Box>
    </Box>
  );
};
