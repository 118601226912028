import { api } from './api';
import { ListResponse } from './types';
import { BillingAccountResponse } from './billingAccount';
import { cleanRequest } from '../utils/requestUtils';

export interface Organization {
  id: string;
  name: string;
  logoUrl?: string;
  billingAccount: {
    id: string;
    name: string;
  };
  defaultEnvironments?: {
    name: string;
    alternateId: string;
    color: string;
  }[];
}

export interface CreateOrganizationAndBillingAccount {
  name: string;
  billingAccount: {
    name: string;
    email: string;
    phone?: string;
    address: {
      streetAddress: string;
      locality: string;
      region: string;
      postalCode: string;
      country: string;
    };
  };
}

export const organizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    createOrganizationAndBillingAccount: build.mutation<Organization, CreateOrganizationAndBillingAccount>({
      query: (data) => ({
        url: 'api/organizations',
        method: 'POST',
        body: cleanRequest(data),
      }),
      invalidatesTags: () => [{ type: 'Organizations', id: 'LIST' }],
    }),
    listOrganizations: build.query<ListResponse<Organization>, void>({
      query: () => ({ url: 'api/organizations' }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Organizations' as const, id })),
              { type: 'Organizations', id: 'LIST' },
            ]
          : [{ type: 'Organizations', id: 'LIST' }],
    }),
    getOrganization: build.query<Organization, string>({
      query: (id) => ({ url: `api/organizations/${id}` }),
      providesTags: (result) => (result ? [{ type: 'Organizations', id: result.id }] : []),
    }),
    getOrganizationBillingAccount: build.query<BillingAccountResponse, string>({
      query: (id) => ({ url: `api/organizations/${id}/billing-account` }),
      providesTags: (result) => (result ? [{ type: 'Organizations', id: `${result.id}_BillingAccount` }] : []),
    }),
    uploadOrganizationLogo: build.mutation<void, { id: string; file: File }>({
      query: ({ id, file }) => {
        const formData = new FormData();
        formData.append('logo', file);
        return {
          url: `api/organizations/${id}/logo`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: 'LIST' },
        { type: 'Organizations' as const, id },
      ],
    }),
    updateOrganization: build.mutation<
      Organization,
      { id: string; data: Omit<Organization, 'logoUrl' | 'id' | 'billingAccount'> }
    >({
      query: ({ id, data }) => ({
        url: `api/organizations/${id}`,
        method: 'PATCH',
        body: cleanRequest(data),
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Organizations', id },
              { type: 'Organizations' as const, id },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateOrganizationAndBillingAccountMutation,
  useListOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationBillingAccountQuery,
  useUploadOrganizationLogoMutation,
  useUpdateOrganizationMutation,
} = organizationApi;
