import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';
import { useUpdateAppSettingsMutation } from '../../services/apps';
import { EnvAccessControlItemProps, getAccessControlsBefore, updateAccessControls } from './EnvAccessControlItem';

interface DeleteAccessControlItemProps
  extends Omit<EnvAccessControlItemProps, 'isInherited' | 'canUpdate' | 'accessControl'> {}

export default function DeleteAccessControlItem({
  app,
  isDefault,
  index,
  environmentAlternateId,
  type,
}: DeleteAccessControlItemProps) {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [updateAppSettings, { isLoading: appUpdateLoading }] = useUpdateAppSettingsMutation();

  const handleClick = useCallback(() => {
    openDeleteModal();
  }, [openDeleteModal]);

  const handleDelete = useCallback(async () => {
    const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
    const listBefore = accessControlsBefore[type] || [];
    const updatedList = [...listBefore.slice(0, index), ...listBefore.slice(index + 1)];

    const { error } = await updateAccessControls(
      app,
      type,
      updatedList,
      isDefault,
      updateAppSettings,
      environmentAlternateId,
    );

    if (error) {
      toast.error('Failed to delete access control rule');
    }

    if (!error) {
      toast.success('Access control rule deleted');
    }
  }, [app, isDefault, environmentAlternateId, type, index, updateAppSettings]);

  return (
    <div>
      <ConfirmModal
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={appUpdateLoading}
        title="Delete Firewall Rule?"
        message={isDefault ? 'This will also delete the rule from all environments' : undefined}
      />
      <Button variant="danger" size="sm" onClick={handleClick}>
        Delete
      </Button>
    </div>
  );
}
