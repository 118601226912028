import { Box, Button, useOpenClose, toast, BadgeVariant, BadgeSize } from '@hyphen/hyphen-components';
import { App, CidrAllowDenyEntry, EnvAccessControls, useUpdateAppSettingsMutation } from '../../services/apps';
import { useCallback, useState } from 'react';
import EnvAccessControlForm, { EditCidrAccessControlSchema } from './EnvAccessControlForm';
import { ApiError } from '../ApiError';
import { ListItem } from '../ListItem';
import DeleteAccessControlItem from './DeleteAccessControlItem';

export interface EnvAccessControlItemProps {
  app: App;
  type: 'allow' | 'deny';
  index: number;
  accessControl: CidrAllowDenyEntry;
  isDefault: boolean;
  environmentAlternateId?: string;
  canUpdate: boolean;
  isInherited: boolean;
}

export default function EnvAccessControlItem({
  app,
  type,
  index,
  isDefault,
  environmentAlternateId,
  accessControl,
  canUpdate,
  isInherited,
}: EnvAccessControlItemProps) {
  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();
  const [updateAppSettings, { isLoading: appUpdateLoading, error }] = useUpdateAppSettingsMutation();
  const [isEditing, setEditing] = useState(false);
  const handleEditClick = useCallback(() => {
    closePopover();
    setEditing(true);
  }, [closePopover]);
  const handleEditCancel = useCallback(() => {
    setEditing(false);
  }, []);

  const initialValues = {
    description: accessControl.description,
    cidr: accessControl.cidr,
  };

  const badgeProps = isInherited
    ? {
        variant: 'light-grey' as BadgeVariant,
        message: 'inherited',
        size: 'sm' as BadgeSize,
        popoverMessage: 'Rule is inherited from default (all) environment',
      }
    : {
        variant: 'blue' as BadgeVariant,
        message: 'direct',
        size: 'sm' as BadgeSize,
        popoverMessage: 'Access was given directly to this environment',
      };

  const handleEditSave = useCallback(
    async (data: EditCidrAccessControlSchema) => {
      const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
      const listBefore = accessControlsBefore[type] || [];
      const updatedList = [
        ...listBefore.slice(0, index),
        { type: 'cidr' as const, description: data.description, cidr: data.cidr },
        ...listBefore.slice(index + 1),
      ];

      const { error } = await updateAccessControls(
        app,
        type,
        updatedList,
        isDefault,
        updateAppSettings,
        environmentAlternateId,
      );

      if (!error) {
        toast.success('Updated access control rule');
        handleEditCancel();
      }
    },
    [app, handleEditCancel, index, isDefault, environmentAlternateId, type, updateAppSettings],
  );

  const popoverContent = isInherited ? undefined : (
    <>
      <Button size="sm" variant="secondary" type="button" onClick={handleEditClick} disabled={appUpdateLoading}>
        Edit
      </Button>
      <DeleteAccessControlItem
        app={app}
        isDefault={isDefault}
        index={index}
        environmentAlternateId={environmentAlternateId}
        type={type}
      />
    </>
  );

  return (
    <Box borderWidth="sm 0 0 0" borderColor="subtle" className="row-item" width="100">
      {isEditing ? (
        <EnvAccessControlForm
          isLoading={appUpdateLoading}
          handleSave={handleEditSave}
          handleCancel={handleEditCancel}
          initialValues={initialValues}
        />
      ) : (
        <>
          <ListItem
            badge={!isDefault ? badgeProps : undefined}
            title={{ label: accessControl.description }}
            infoText={accessControl.cidr}
            popover={{ content: popoverContent, isOpen: isPopoverOpen, onClickOutside: closePopover }}
            togglePopover={isInherited ? undefined : togglePopover}
          />
        </>
      )}
      {error && <ApiError error={error} title="Error updating access control rules" />}
    </Box>
  );
}

// These below helpers are exported to be used by add as well.

export const getAccessControlsBefore = (app: App, isDefault: boolean, environmentAlternateId?: string) => {
  if (isDefault) {
    return app.settings?.env?.accessControls || { allow: [], deny: [] };
  }
  return app.settings?.env?.accessControls?.environments?.[environmentAlternateId!] || { allow: [], deny: [] };
};

export const buildUpdatedAccessControls = (
  accessControlsBefore: EnvAccessControls,
  type: 'allow' | 'deny',
  updatedList: Array<CidrAllowDenyEntry>,
  isDefault: boolean,
  environmentAlternateId?: string,
) => {
  if (isDefault) {
    return {
      ...accessControlsBefore,
      [type]: updatedList,
    };
  }

  return {
    environments: {
      [environmentAlternateId!]: {
        ...accessControlsBefore,
        [type]: updatedList,
      },
    },
  };
};

export const updateAccessControls = async (
  app: App,
  type: 'allow' | 'deny',
  updatedList: Array<CidrAllowDenyEntry>,
  isDefault: boolean,
  updateAppSettings: ReturnType<typeof useUpdateAppSettingsMutation>[0],
  environmentAlternateId?: string,
) => {
  const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
  const updatedAccessControls = buildUpdatedAccessControls(
    accessControlsBefore,
    type,
    updatedList,
    isDefault,
    environmentAlternateId,
  );

  const results = await updateAppSettings({
    organizationId: app.organization.id,
    appId: app.id,
    body: {
      settings: {
        env: {
          accessControls: updatedAccessControls,
        },
      },
    },
  });

  return results;
};
