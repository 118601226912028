import { Drawer, Box, useBreakpoint, Spinner } from '@hyphen/hyphen-components';
import { EventList } from './EventList';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { App, useGetAppQuery } from '../../services/apps';
import Error from '../../Error';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { subject } from '@casl/ability';
import { EntityNames } from '../../types/executionContext';
import { useGetProjectEnvironmentQuery } from '../../services/environments';
import EnvironmentEnvVersionsList from './EnvironmentEnvVersionsList';
import { EventType } from '../../types/events';
import EnvironmentEnvAccessControls from './EnvironmentEnvAccessControls';
import { TabNav, TabNavLink } from '../TabNav';

export default function EnvEnvironmentDrawer() {
  const { appId, projectId, pevrId } = useParams();
  const { organization = {} as Organization } = useOrganization();
  const navigate = useNavigate();

  const {
    data: app = {} as App,
    error,
    isLoading,
  } = useGetAppQuery({ appId: appId!, organizationId: organization.id });

  const { data: environment, isLoading: environmentIsLoading } = useGetProjectEnvironmentQuery({
    organizationId: organization.id,
    projectId: projectId!,
    environmentId: pevrId!,
  });

  const { isPhone, isTablet } = useBreakpoint();

  const onDismiss = () => {
    navigate(`/${organization.id}/${projectId}/app/${appId}`);
  };

  const ability = useOrganizationAbilityContext();

  const canReadEnv = ability.can(
    'read',
    subject(EntityNames.Env, { project: { id: app?.project?.id }, organization: { id: organization.id } }),
  );

  if (!isLoading && error) return <Error />;

  return (
    <Drawer
      isOpen
      onDismiss={onDismiss}
      // @ts-ignore need to update hyphen-components to accept JSX.Element
      title={
        <Box gap="sm" direction="row" alignItems="center">
          {app.name}{' '}
          <Box
            direction="row"
            gap="sm"
            background="secondary"
            padding="xs sm"
            radius="sm"
            fontFamily="monospace"
            fontSize="sm"
            fontWeight="normal"
          >
            {environment?.color && (
              <Box
                style={{ backgroundColor: environment?.color }}
                width="xl"
                height="xl"
                minWidth="xl"
                minHeight="xl"
                radius="xs"
                borderColor="subtle"
                borderWidth="sm"
              />
            )}
            {environmentIsLoading ? '' : environment?.name || 'Default (all)'}
          </Box>
        </Box>
      }
      ariaLabel="environment details"
      width={isPhone ? '90%' : isTablet ? '70%' : '60%'}
      style={{ height: '100%' }}
    >
      <Box
        padding={{ base: '0 2xl 2xl 2xl', tablet: '0 4xl 4xl 4xl' }}
        overflow="auto"
        height="100"
        gap="4xl"
        fontSize="sm"
      >
        {isLoading || environmentIsLoading ? (
          <Spinner />
        ) : error ? (
          <Error />
        ) : (
          <>
            <TabNav>
              <TabNavLink to="">Access Log</TabNavLink>
              <TabNavLink to="firewall">Firewall</TabNavLink>
              <TabNavLink to="versions">Versions</TabNavLink>
            </TabNav>

            <Routes>
              {canReadEnv && (
                <Route
                  path="firewall"
                  element={
                    <EnvironmentEnvAccessControls
                      app={app}
                      isDefault={pevrId === '*'}
                      environmentAlternateId={environment?.alternateId}
                    />
                  }
                />
              )}
              {canReadEnv && (
                <Route
                  path="versions"
                  element={<EnvironmentEnvVersionsList orgId={organization.id} appId={app.id} pevrId={pevrId!} />}
                />
              )}
              <Route
                path="/*"
                element={
                  <EventList
                    appId={app.id}
                    title="ENV Access Log"
                    pevrId={pevrId}
                    types={[
                      EventType.GetEnvData,
                      EventType.CreateEnvData,
                      EventType.DeleteEnvData,
                      EventType.GetEnvDataDeny,
                    ]}
                  />
                }
              />
            </Routes>
          </>
        )}
      </Box>
    </Drawer>
  );
}
