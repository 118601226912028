import { Modal, Button, Box, ButtonVariant } from '@hyphen/hyphen-components';
import { ApiError, CustomMessages } from '../ApiError';
import { ReactNode } from 'react';

interface ConfirmModalProps {
  message: ReactNode;
  confirmButtonLabel: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  confirmButtonVariant?: ButtonVariant;
  title?: string;
  description?: string;
  error?: any;
  customErrorMessages?: CustomMessages;
}

export const ConfirmModal = ({
  message,
  confirmButtonLabel,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  confirmButtonVariant = 'danger',
  title,
  description,
  error,
  customErrorMessages,
}: ConfirmModalProps) => {
  return (
    <Modal ariaLabel="Confirm" isOpen={isOpen} onDismiss={onClose} maxWidth="9xl" loading={isLoading}>
      {title && <Modal.Header id="confirmModalTitle" title={title} />}
      <Modal.Body gap="2xl">
        <Box childGap="sm" display="block">
          {message}
        </Box>
        {description && <Box display="block">{description}</Box>}
        {error ? <ApiError error={error} customMessages={customErrorMessages} /> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button isDisabled={isLoading} variant="secondary" onClick={onClose} shadow="sm">
          Cancel
        </Button>
        <Button isLoading={isLoading} variant={confirmButtonVariant} onClick={onConfirm} shadow="sm">
          {confirmButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
