import { useDeleteTeamMutation } from '../../services/teams';
import { Badge, Box, Button, Popover, toast, useOpenClose } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamDetails } from '../../types/teams';
import ClickToCopy from '../common/ClickToCopy';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

export const TeamItem = ({ team }: { team: TeamDetails }) => {
  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [deleteTeam, { isLoading: isDeleting, error }] = useDeleteTeamMutation();
  const { organization = {} as Organization } = useOrganization();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteTeam({ orgId: organization.id, id: team.id });
    if (!error) {
      toast.success('Team removed!');
      closeDeleteModal();
    }
  }, [closeDeleteModal, organization.id, team.id, deleteTeam]);

  const ability = useOrganizationAbilityContext();
  const canManageThisTeam = ability.can('manage', team);
  const canManageAnyTeam = ability.can('manage', EntityNames.Team);

  const isDynamicTeam = team.type === 'dynamic';

  const popoverContent = (
    <>
      {/* {!isDynamicTeam && (
        <Link
          role="menuitem"
          to={`${team.id}/add`}
          className="navlink hover:background-color-secondary display-block p-sm br-sm"
        >
          Add Members
        </Link>
      )} */}
      <Link
        role="menuitem"
        to={`${team.id}/edit`}
        className="navlink hover:background-color-secondary display-block p-sm br-sm"
      >
        Settings
      </Link>
      <Box
        role="divider"
        borderColor="default"
        borderWidth="sm 0 0 0"
        height="1px"
        margin="xs 0"
        style={{ marginLeft: 'calc(-1 * var(--size-spacing-sm))', marginRight: 'calc(-1 * var(--size-spacing-sm))' }}
      />
      <Box
        as="button"
        type="button"
        textAlign="left"
        padding="sm"
        borderWidth="0"
        background="transparent"
        color="danger"
        radius="sm"
        onClick={() => {
          openDeleteModal();
          closePopover();
        }}
        cursor="pointer"
        hover={{
          background: 'danger',
        }}
      >
        Delete
      </Box>
    </>
  );

  return (
    <>
      <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
        <Box direction="row" alignItems="center" padding={{ base: '2xl', desktop: '3xl' }} gap="2xl">
          <Box
            flex="auto"
            direction={{ base: 'column', tablet: 'row' }}
            gap={{ base: 'sm', tablet: '2xl' }}
            alignItems={{ base: 'flex-start', tablet: 'center' }}
            justifyContent="space-between"
          >
            <Box gap="sm" flex="auto" alignItems="flex-start">
              <Box fontSize="md" fontWeight="bold" alignItems="center" direction="row" gap="lg">
                <Link to={team.id}>{team.name.replace(/\s/g, '-')}</Link>
                {isDynamicTeam && <Badge message="dynamic" variant="purple" size="sm" />}
              </Box>
              {/* TODO: replace and with real alias */}
              {team.distributionList && <ClickToCopy text={team.distributionList.email} hideIcon fontSize="xs" />}
            </Box>
            <Box
              fontSize={{ base: 'xs', desktop: 'sm' }}
              direction="row"
              gap="2xl"
              justifyContent={{ tablet: 'space-between' }}
              width={{ base: '100', tablet: '7xl' }}
            >
              <Box color="secondary">
                {`${team.memberCount.toString()} member${team.memberCount !== 1 ? 's' : ''}`}
              </Box>
              {/* <Box color="secondary">0 roles</Box> */}
              <Box color="secondary">
                {`${team.channels?.length} channel${team.channels?.length !== 1 ? 's' : ''}`}
              </Box>
            </Box>
          </Box>
          {canManageThisTeam ? (
            <Popover
              content={popoverContent}
              isOpen={isPopoverOpen}
              placement="bottom-end"
              hasArrow={false}
              offsetFromTarget={3}
              contentContainerProps={{
                padding: 'sm',
                gap: 'xs',
                borderWidth: 'sm',
                borderColor: 'default',
                fontSize: 'sm',
                width: '7xl',
              }}
              onClickOutside={closePopover}
            >
              <Box width="4xl">
                <Button
                  variant="tertiary"
                  size="sm"
                  iconPrefix="dots"
                  onClick={togglePopover}
                  aria-label="open menu"
                ></Button>
              </Box>
            </Popover>
          ) : (
            canManageAnyTeam && <Box width="4xl"></Box>
          )}
        </Box>
      </Box>
      <ConfirmModal
        message={`Are you sure you want to delete ${team.name}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${team.name}`}
        error={error}
      />
    </>
  );
};
