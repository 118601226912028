import { Box, Pagination, Spinner, Table } from '@hyphen/hyphen-components';
import { Project, useGetAccessQuery } from '../../services/projects';
import useQueryStringState from '../../hooks/useQueryStringState';
import { useCallback } from 'react';
import { RoleDisplayName } from '../../utils/roles';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import RevokeAccess from './RevokeAccess';
import BadgePopover from '../BadgePopover';

export default function ProjectAccessList({ project }: { project: Project }) {
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const { data: access, isLoading } = useGetAccessQuery({
    projectId: project.id,
    organizationId: project.organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);

  const accessWithType = (access?.data || [])
    .map((item: any) => ({
      ...item,
      accessType: item.entity.type === 'Project' && item.entity.id === '*' ? 'inherited' : 'direct',
    }))
    .sort((a: any, b: any) => (a.accessType === 'direct' ? -1 : 1));

  const columnConfig = [
    {
      heading: 'Access Type',
      width: 100,
      dataKey: 'accessType',
      render: (accessType: any) =>
        accessType === 'inherited' ? (
          <BadgePopover
            size="sm"
            variant="light-grey"
            message="global"
            popoverMessage="This access is applied to all Projects and set at the organization level"
          />
        ) : (
          <BadgePopover
            size="sm"
            variant="blue"
            message="direct"
            popoverMessage="Access was given directly to this project"
          />
        ),
    },
    {
      heading: 'Name',
      dataKey: 'assignment',
      render: (assignment: any) => {
        if (assignment.type === 'Team') return <>{assignment.name} (Team)</>;
        else return <>{assignment.name}</>;
      },
    },
    {
      heading: 'Roles',
      dataKey: 'roles',
      render: (roles: any) => (roles || []).map(RoleDisplayName).join(', '),
    },
  ];

  if (canUpdateProject) {
    columnConfig.push({
      // @ts-ignore
      render: (_cell: any, row: any) =>
        row.accessType === 'direct' ? (
          <Box alignItems="flex-end">
            <RevokeAccess
              name={row.assignment.name}
              projectId={project.id}
              accessId={row.id}
              organizationId={project.organization.id}
            />
          </Box>
        ) : null,
    });
  }

  return (
    <>
      <Box alignItems="flex-start" gap="xl">
        {isLoading ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <Box width="100" gap="lg" fontSize="sm">
            <Table
              rowKey="id"
              isLoading={isLoading}
              columns={columnConfig}
              rows={accessWithType || []}
              isScrollable={{ x: true, y: false }}
            />
            {access?.total && access?.total > qsState.pageSize ? (
              <Pagination
                activePage={qsState.pageNum}
                itemsPerPage={qsState.pageSize}
                onChange={handlePageNumberChange}
                totalItemsCount={access?.total ?? 0}
                numberOfPagesDisplayed={3}
                isCompact
                arePagesVisible
              />
            ) : null}
          </Box>
        )}
      </Box>
    </>
  );
}
