import { useCallback } from 'react';
import { Box, Button, Popover, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useDeleteDomainMutation } from '../../services/zelda/domains';
import { Link } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { formatDateWithTime } from '../../utils/dateUtils';
import { DnsStatus, Domain, SslStatus } from '../../types/zelda';
import { ConfirmModal } from '../common/ConfirmModal';

const customErrorMessages = {
  default: 'Failed to remove domain',
};

export const DomainItem = ({
  domain,
  dateAdded,
  organization,
  canDeleteDomain,
}: {
  domain: Domain;
  dateAdded: string;
  organization?: Organization;
  canDeleteDomain: boolean;
}) => {
  const [deleteDomain, { isLoading: isDeleting, error }] = useDeleteDomainMutation();

  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();
  const { isOpen: isMenuModalOpen, handleToggle: toggleMenuModal, handleClose: closeMenuModal } = useOpenClose();

  const popoverContent = (
    <Button
      variant="danger"
      size="sm"
      onClick={() => {
        closeMenuModal();
        openRemoveModal();
      }}
    >
      Remove
    </Button>
  );

  const handleRemove = useCallback(async () => {
    const result = await deleteDomain({ domainId: domain.id, organizationId: organization?.id });
    if (!result.error) {
      toast.success('Domain removed!');
      closeRemoveModal();
    }
  }, [closeRemoveModal, deleteDomain, domain.id, organization?.id]);

  return (
    <Box
      className="row-item"
      borderWidth="sm 0 0 0"
      borderColor="default"
      background={domain.dnsStatus === DnsStatus.Pending ? 'warning' : undefined}
    >
      <ConfirmModal
        message={`Are you sure you want to remove ${domain.domain}?`}
        confirmButtonLabel="Remove Domain"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={handleRemove}
        isLoading={isDeleting}
        title={`Remove ${domain.domain}`}
        description="Short URLs that use this domain will no longer work."
        error={error}
        customErrorMessages={customErrorMessages}
      />
      <Box direction="row" gap="2xl" padding="2xl" alignItems="center">
        <Box gap="xs" flex="auto">
          <Box
            fontSize="md"
            fontWeight="bold"
            direction="row"
            alignItems="center"
            gap="sm"
            color={domain.dnsStatus === DnsStatus.Pending ? 'grey' : undefined}
          >
            {domain.domain}
          </Box>
          <Box fontSize="xs" color={domain.dnsStatus === DnsStatus.Pending ? 'grey' : 'secondary'}>
            added {formatDateWithTime(dateAdded)}
          </Box>
        </Box>
        {domain.dnsStatus === DnsStatus.Verified ? (
          <>
            <Box
              width="5xl"
              fontSize="xs"
              fontFamily="monospace"
              color={domain.dnsStatus === DnsStatus.Verified ? 'success' : 'warn'}
            >
              {domain.dnsStatus}
            </Box>
            <Box
              width="5xl"
              fontSize="xs"
              fontFamily="monospace"
              color={
                domain.sslStatus === SslStatus.NotReady
                  ? 'secondary'
                  : domain.sslStatus === SslStatus.Generating
                  ? 'warn'
                  : 'success'
              }
            >
              {domain.sslStatus === SslStatus.NotReady ? 'not ready' : domain.sslStatus}
            </Box>
          </>
        ) : (
          <Box fontSize="sm">
            <Link
              to={`/${organization?.id}/settings/domains/${domain.id}/add`}
              state={{ domain }}
              style={{ color: 'var(--color-font-grey)' }}
            >
              Pending DNS verification
            </Link>
          </Box>
        )}
        {canDeleteDomain ? (
          <Popover
            content={popoverContent}
            isOpen={isMenuModalOpen}
            placement="bottom-end"
            hasArrow={false}
            offsetFromTarget={3}
            contentContainerProps={{
              padding: 'sm',
              gap: 'xs',
              borderWidth: 'sm',
              borderColor: 'default',
            }}
            onClickOutside={closeMenuModal}
          >
            <Button
              variant="tertiary"
              size="sm"
              iconPrefix="dots"
              onClick={toggleMenuModal}
              aria-label="open menu"
            ></Button>
          </Popover>
        ) : (
          <Box width="36px" />
        )}
      </Box>
    </Box>
  );
};
