import { Box, Icon, Popover, useOpenClose } from '@hyphen/hyphen-components';
import { formatDateWithTime } from '../utils/dateUtils';

export const DateCreated = ({ dateCreated }: { dateCreated: string }) => {
  const {
    isOpen: isDateCreatedPopoverOpen,
    handleClose: closeDateCreatedPopover,
    handleOpen: openDateCreatedPopover,
  } = useOpenClose();

  return (
    <Popover
      content="date created"
      isOpen={isDateCreatedPopoverOpen}
      placement="top"
      offsetFromTarget={4}
      contentContainerProps={{
        padding: 'md',
        color: 'inverse',
        background: 'inverse',
      }}
    >
      <Box
        as="button"
        borderWidth="0"
        onMouseOver={openDateCreatedPopover}
        onMouseOut={closeDateCreatedPopover}
        onFocus={openDateCreatedPopover}
        onBlur={closeDateCreatedPopover}
        direction="row"
        gap="sm"
        display="inline-flex"
        alignItems="center"
        padding="sm"
        background="secondary"
        radius="md"
        color="secondary"
        fontSize="xs"
      >
        <Icon name="calendar-create" size="md" />
        <Box padding="xs 0 0 0">{formatDateWithTime(dateCreated)}</Box>
      </Box>
    </Popover>
  );
};
