import { Box } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import ApiKeyList from '../../components/apiKeys/ApiKeyList';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';

export default function ApiKeys() {
  const { organization = {} as Organization, isLoading } = useOrganization();
  return (
    <Box width="100" maxWidth="10xl" gap="4xl">
      <Helmet>
        <title>API Keys</title>
      </Helmet>
      <PageHeader title="API Keys" />

      {!isLoading && <ApiKeyList organizationId={organization.id} />}
    </Box>
  );
}
